import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { getRequest } from '../../../../utils/axiosRequests';
import { selectDefaultUnit, sortMedicines } from '../../../../utils/helpers';
import SearchDrug from './searchDrug';
import SelectedDrugList from './selectedDrugList';

const DrugHistories = ({ selectedData, setSelectedHistories }) => {
  const [editMedicineId, setEditMedicineId] = useState('');
  const [medicinesInSearch, setMedicinesInSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const medicineTypes = useSelector(
    (state) => state.medicineTypeReducer.medTypes,
  );

  const handleOnInputChange = async (searchKey) => {
    setIsLoading(true);
    const url = `medicines?any=${searchKey}&search=yes`;

    await getRequest(url)
      .then((data) => {
        if (data.data.length > 0) {
          const medicines = data.data;
          const sortedMedicines = sortMedicines({
            medicines,
          });
          setMedicinesInSearch([...sortedMedicines]);
        } else {
          setMedicinesInSearch([]);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const medicineUpdated = (updatedData, index) => {
    selectedData['drugHistories']['D_H'][index] = updatedData;
    setSelectedHistories({ ...selectedData });
  };

  const selectMedicines = async (item, type) => {
    console.log(selectedData, 'selectedData');
    let tempItem = { ...item };
    if (
      !selectedData?.drugHistories[type]?.some(
        (selectedMedicine) => selectedMedicine.id === tempItem.id,
      )
    ) {
      tempItem.name = tempItem['brandName'];
      tempItem.type = tempItem.type;
      tempItem.alterMedicine = '';
      tempItem.itemDetails = [
        {
          quantitiesField: [1, 2, 3],
          schedule: '',
          scheduleUnit: 'বার',
          route: '',
          quantities: { first: 0, second: 0, third: 0 },
          quantity: '',
          quantityUnit: selectDefaultUnit(medicineTypes, tempItem?.type),
          times: '১',
          timesUnit: 'বেলা',
          duration: '',
          durationUnit: 'দিন',
          instructions: [],
        },
      ];
      let result = [...selectedData.drugHistories[type], tempItem];
      selectedData.drugHistories[type] = result;
      setSelectedHistories({ ...selectedData });
    } else {
      unselectMedicine(item, type);
    }
  };

  const unselectMedicine = (type, itemId) => {
    const result = selectedData?.drugHistories[type]?.filter(
      (selectedItem) => selectedItem.id !== itemId,
    );
    selectedData.drugHistories[type] = result;
    setSelectedHistories({ ...selectedData });
  };

  const handleSelectedMedicine = (
    fieldName,
    medicineId,
    value,
    itemDetailIndex,
  ) => {
    const drugHistories = [...selectedData?.drugHistories?.D_H];
    const objIndex = drugHistories.findIndex(
      (medicine) => medicine.id === medicineId,
    );

    if (objIndex === -1) {
      return '';
    }

    switch (fieldName) {
      case 'quantities':
      case 'quantitiesField':
      case 'quantityUnit':
      case 'route':
      case 'quantity':
      case 'schedule':
      case 'scheduleUnit':
        drugHistories[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        break;
      case 'duration':
        drugHistories[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        if (value === '') {
          drugHistories[objIndex]['itemDetails'][itemDetailIndex][
            'durationUnit'
          ] = '';
        }
        break;
      case 'durationUnit':
        drugHistories[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
          value;
        if (value === '' || value === 'চলবে') {
          drugHistories[objIndex]['itemDetails'][itemDetailIndex]['duration'] =
            '';
        }
        break;
      case 'instructions':
        drugHistories[objIndex]['itemDetails'][itemDetailIndex][fieldName] = [
          ...new Set([
            ...drugHistories[objIndex]['itemDetails'][itemDetailIndex][
              fieldName
            ],
            value,
          ]),
        ];
        break;
      case 'instructionRemove':
        drugHistories[objIndex]['itemDetails'][itemDetailIndex][
          'instructions'
        ] = value;
        break;
      case 'itemDetails':
        drugHistories[objIndex][fieldName] = value;
        break;
      default:
    }
    // setSelectedHistories({
    //   ...selectedData,
    //   drugHistories: { ...selectedData.drugHistories, D_H: drugHistories },
    // });
    setSelectedHistories((prev) => ({
      ...prev,
      drugHistories: {
        ...(prev?.drugHistories || {
          D_H: [],
          D_A: [],
        }),
        D_H: drugHistories,
      },
    }));
  };

  // const handleSelectedMedicine = (
  //   fieldName,
  //   medicineId,
  //   value,
  //   itemDetailIndex,
  // ) => {
  //   const drugHistories = [...selectedData?.drugHistories?.D_H];
  //   const objIndex = drugHistories.findIndex(
  //     (medicine) => medicine.id == medicineId,
  //   );
  //   switch (fieldName) {
  //     case 'quantities':
  //     case 'quantitiesField':
  //     case 'quantityUnit':
  //     case 'quantity':
  //     case 'schedule':
  //     case 'scheduleUnit':
  //       drugHistories[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
  //         value;
  //       break;
  //     case 'duration':
  //       drugHistories[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
  //         value;
  //       if (value === '') {
  //         drugHistories[objIndex]['itemDetails'][itemDetailIndex][
  //           'durationUnit'
  //         ] = '';
  //       }
  //       break;
  //     case 'durationUnit':
  //       drugHistories[objIndex]['itemDetails'][itemDetailIndex][fieldName] =
  //         value;
  //       if (value === '' || value === 'চলবে') {
  //         drugHistories[objIndex]['itemDetails'][itemDetailIndex]['duration'] =
  //           '';
  //       }
  //       break;
  //     case 'instructions':
  //       drugHistories[objIndex]['itemDetails'][itemDetailIndex][fieldName] = [
  //         ...new Set([
  //           ...drugHistories[objIndex]['itemDetails'][itemDetailIndex][
  //             fieldName
  //           ],
  //           value,
  //         ]),
  //       ];
  //       break;
  //     case 'instructionRemove':
  //       drugHistories[objIndex]['itemDetails'][itemDetailIndex][
  //         'instructions'
  //       ] = value;
  //       break;
  //     case 'itemDetails':
  //       drugHistories[objIndex][fieldName] = value;
  //       break;
  //     case 'similarMedicine':
  //       let replacedData = drugHistories[objIndex];
  //       replacedData.name = value?.brandName;
  //       replacedData.id = value?.id;
  //       replacedData.brandName = value?.brandName;
  //       replacedData.companyName = value?.companyName;
  //       replacedData.genericName = value?.genericName;
  //       replacedData.strength = value?.strength;
  //       replacedData.type = value?.type;
  //       replacedData.altName = value?.altName;
  //       replacedData.createdBy = value?.createdBy;
  //       drugHistories[objIndex] = replacedData;
  //       break;
  //   }
  //   setSelectedHistories({
  //     ...selectedData,
  //     drugHistories: { ...selectedData.drugHistories, D_H: drugHistories },
  //   });
  // };

  return (
    <Row className="selected-drug-histories">
      <Col>
        <Form.Group as={Row} className="mb-3 mt-2 common-font-size">
          <Form.Label column sm="1">
            <span className="pl-3">D/H:</span>
          </Form.Label>
          <Col sm="10" className="mt-1">
            <SearchDrug
              type={'D_H'}
              selectMedicines={selectMedicines}
              handleOnInputChange={handleOnInputChange}
              medicinesInSearch={medicinesInSearch}
              setMedicinesInSearch={setMedicinesInSearch}
            />
            <SelectedDrugList
              selectedData={selectedData}
              unselectMedicine={unselectMedicine}
              editMedicineId={editMedicineId}
              setEditMedicineId={setEditMedicineId}
              handleSelectedMedicine={handleSelectedMedicine}
              medicineUpdated={medicineUpdated}
            />
          </Col>
        </Form.Group>
      </Col>
    </Row>
  );
};

export default DrugHistories;
