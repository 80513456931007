import React, { Fragment, memo, useEffect, useState } from 'react';
import { Col, Form, Modal, Row, Tab } from 'react-bootstrap';
import { useGetRequest } from '../../../hooks/useGetRequest';
import { getRequest, postRequest } from '../../../utils/axiosRequests';
import DrugHistories from '../partials/history/drugHistories';
import GynecologyHistory from '../partials/history/gynecologyHistory';
import InvestigationHistories from '../partials/history/investigationHistories';
import MedicalHistory from '../partials/history/medicalHistory';
import MedicalHistoryGroup from '../partials/history/medicalHistoryGroup';
import Others from '../partials/history/others';
import PersonalInfo from '../partials/history/personalInfo';
import SelectedMedicalHistories from '../partials/history/selectedMedicalHistories';
import Surgical from '../partials/history/surgical';
import HistoryTabNav from '../partials/historyTabNav';
import NonSearchHistory from '../partials/nonSearchHistory';
import SearchArea from '../partials/searchAreaAsync';
import SearchItemSuggestions from '../searchItemSuggestions';
import MedicalHistoryGroupModal from './medicalHistoryGroupModal';

const HistoryModal = ({
  modalTitle,
  showHistory,
  setShowHistory,
  selectedHistoryTab,
  setSelectedHistoryTab,
  selectedHistories,
  setSelectedHistories,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [medicalHistories, setMedicalHistories] = useState([]);
  const [medicalHistoryInSearch, setMedicalHistoryInSearch] = useState([]);
  const [medicalHistoryGroups, setMedicalHistoryGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isGroupModal, setIsGroupModal] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [clickedItemForShowSuggestion, setClickedItemForShowSuggestion] =
    useState('');
  const [isSubItemSuggestionOpen, setIsSubItemSuggestionOpen] = useState(false);
  const [suggetionMenuTop, setSuggetionMenuTop] = useState(4);
  let selectedData =
    Object.keys(selectedHistories).length !== 0
      ? selectedHistories
      : {
          medical: [],
          drugs: {
            D_H: [],
            D_A: [],
          },
          drugHistories: {
            D_H: [],
            D_A: [],
          },
          investigations: [],
          personal: {
            smoker: false,
            alcoholic: false,
            tobacco: false,
            notes: '',
          },
          family: '',
        };

  let { medical } = selectedData;
  medical = Array.isArray(medical) ? selectedData?.medical : [];

  useEffect(() => {
    getMedicalHistoryList();
    getMedicalHistoryGroupList();
    getSuggestions();
  }, []);

  useEffect(() => {
    if (showHistory) {
      getSuggestions();
    } else {
      setSearchQuery('');
      setMedicalHistoryInSearch([]);
    }
  }, [showHistory]);

  const getMedicalHistoryList = async () => {
    await getRequest(`medical-histories?perPage=500`)
      .then((data) => {
        setMedicalHistories(data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getMedicalHistoryGroupList = async () => {
    await getRequest('medical-histories/groups')
      .then((data) => {
        setMedicalHistoryGroups(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const familyHistoryData = (val) => {
    selectedData['family'] = val;
    setSelectedHistories({ ...selectedData });
  };

  const handleSearchOrNew = async (selectedOption) => {
    try {
      const { name: selectedItem, customOption: isNewOption } =
        selectedOption[0];
      if (isNewOption) {
        const data = await postRequest('medical-histories', {
          name: selectedItem,
        });
       
        setMedicalHistories([...medicalHistories, data]);
        setSearchQuery('');
        medical = [
          ...medical,
          {
            name: data.name,
            present: true,
            absent: false,
            unit: 'day(s)',
            duration: '',
            note: '',
          },
        ];
        setSelectedHistories({ ...selectedData, medical });
      } else {
        const isExisting = medical?.some(
          ({ name }) => name?.toLowerCase() === selectedItem?.toLowerCase(),
        );
        const updatedMedical = isExisting
          ? medical?.filter(
              ({ name }) => name?.toLowerCase() !== selectedItem?.toLowerCase(),
            )
          : [
              ...medical,
              {
                name: selectedItem,
                present: true,
                absent: false,
                unit: 'day(s)',
                duration: '',
                note: '',
              },
            ];
        setSelectedHistories({ ...selectedData, medical: updatedMedical });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchMedicine = async (searchKey) => {
    setIsLoading(true);
    const url = `medical-histories?name=${encodeURIComponent(searchKey)}`;
    setSearchQuery(searchKey);
    await getRequest(url)
      .then((data) => {
        setMedicalHistoryInSearch(data?.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const { isLoading: isSuggestionLoading, refetch: getSuggestions } =
    useGetRequest(
      'getHistorySuggestions',
      `prescriptions/suggestions?suggestion_type=medical`,
      (data) => {
        console.log('sug1: ', data.suggestions);
        setSuggestions(data.suggestions);
      },
      (e) => {
        console.log(e);
      },
    );

  //============for search suggestions ======================//
  const openDropdown = () => {
    setIsSubItemSuggestionOpen(true);
  };

  const closeDropdown = () => {
    setIsSubItemSuggestionOpen(false);
  };

  const handleSelectSearchSuggestion = (note, itemName) => {
    const item = medicalHistories.find(
      (medicalItem) => medicalItem.name === itemName,
    );
    item['note'] = note;
    const isItemExist = medical.some((medicalItem) => {
      if (medicalItem.name === item.name) {
        return true;
      }
    });

    let updatedItems = [];
    if (isItemExist) {
      updatedItems = medical.map((medicalItem) => {
        if (medicalItem.name !== item.name) return medicalItem;
        else return { ...medicalItem, note: item?.note ? item?.note : '' };
      });
    } else {
      updatedItems = [
        ...medical,
        {
          name: item.name,
          present: 'yes',
          absent: '',
          unit: 'day(s)',
          duration: '',
          note: item?.note ? item?.note : '',
        },
      ];
    }
    setSelectedHistories({ ...selectedData, medical: updatedItems });
  };

  const handleClickOnRightArrow = (e, itemName) => {
    e.stopPropagation();
    if (clickedItemForShowSuggestion === itemName) {
      setClickedItemForShowSuggestion('');
      closeDropdown();
    } else {
      setClickedItemForShowSuggestion(itemName);
      const topPosition = Math.abs(e.pageY - 160) / 16 + 5;
      setSuggetionMenuTop(topPosition);
      openDropdown();
    }
  };
  //============End search suggestions ======================//

  return (
    <Modal
      show={showHistory}
      size="lg"
      className="customize-modal-size"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {/* <ModalHeader title={modalTitle} action={setShowHistory} /> */}
      <Modal.Body className="pb-2 common-font-size">
        {selectedHistoryTab === 'medical' ? (
          <SearchArea
            modalTitle={modalTitle}
            closeAction={setShowHistory}
            setSearchOptions={setMedicalHistoryInSearch}
            handleOnInputChange={handleSearchMedicine}
            handleSearchOrNew={handleSearchOrNew}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            options={medicalHistoryInSearch}
            selectedInputs={selectedHistories?.medical ?? []}
            placeholder={'medical history'}
            handleClickOnRightArrow={handleClickOnRightArrow}
          />
        ) : (
          <NonSearchHistory
            modalTitle={modalTitle}
            closeAction={setShowHistory}
          />
        )}
        <Tab.Container defaultActiveKey={selectedHistoryTab}>
          <HistoryTabNav
            action={setIsGroupModal}
            selectedItems={selectedData?.medical}
            setSelectedHistoryTab={setSelectedHistoryTab}
          />
          <Tab.Content className="pt-1 pl-1 pr-1 pb-2">
            <Tab.Pane eventKey="medical" className="add-scroll">
              <MedicalHistory
                selectedData={selectedData}
                medical={medical}
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
                medicalHistories={medicalHistories}
                medicalHistoryGroups={medicalHistoryGroups}
                suggestions={suggestions}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="medicalGroup" className="add-scroll">
              <MedicalHistoryGroup
                medicalHistoryGroups={medicalHistoryGroups}
                setMedicalHistoryGroups={setMedicalHistoryGroups}
                selectedGroups={selectedGroups}
                setSelectedGroups={setSelectedGroups}
                selectedHistories={selectedData}
                setSelectedHistories={setSelectedHistories}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="drug">
              <DrugHistories
                selectedData={selectedData}
                setSelectedHistories={setSelectedHistories}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="investigation" className="add-inv-scroll">
              <InvestigationHistories
                selectedData={selectedData}
                setSelectedHistories={setSelectedHistories}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="personal">
              <PersonalInfo
                selectedData={selectedData}
                setSelectedHistories={setSelectedHistories}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="family" className="mb-1 mt-1">
              <Row>
                <Col md="2" className="font-weight-bold mt-1">
                  Note:
                </Col>
                <Col md="10">
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Add note"
                    defaultValue={selectedData.family}
                    onChange={(e) => familyHistoryData(e.target.value)}
                  />
                </Col>
              </Row>
            </Tab.Pane>
            <Tab.Pane
              eventKey="gynecology"
              className="add-scroll"
              style={{ height: '410px' }}
            >
              <GynecologyHistory
                selectedData={selectedData}
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="surgical">
              <Surgical
                selectedData={selectedData}
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="others">
              <Others
                selectedData={selectedData}
                selectedHistories={selectedHistories}
                setSelectedHistories={setSelectedHistories}
              />
            </Tab.Pane>
          </Tab.Content>
          {(selectedHistoryTab === 'medical' ||
            selectedHistoryTab === 'medicalHistory') && (
            <Fragment>
              <hr className="selected-hr" />
              <div className="selected-item-title">Selected list</div>
              <div className="selected-item-area">
                {/* {selectedMedicalHistories()}{' '} */}
                <SelectedMedicalHistories
                  selectedData={{ ...selectedData }}
                  setSelectedHistories={setSelectedHistories}
                />
              </div>
            </Fragment>
          )}
          <MedicalHistoryGroupModal
            isGroupModal={isGroupModal}
            setIsGroupModal={setIsGroupModal}
            itemNames={medical?.map((item) => item.name)}
            selectedGroups={selectedGroups}
            setSelectedGroups={setSelectedGroups}
            medicalHistoryGroups={medicalHistoryGroups}
            setMedicalHistoryGroups={setMedicalHistoryGroups}
          />
        </Tab.Container>

        <SearchItemSuggestions
          openDropdown={openDropdown}
          isSubItemSuggestionOpen={isSubItemSuggestionOpen}
          handleSelect={handleSelectSearchSuggestion}
          closeDropdown={closeDropdown}
          suggestions={suggestions}
          itemName={clickedItemForShowSuggestion}
          suggetionMenuTop={suggetionMenuTop}
        />
      </Modal.Body>
    </Modal>
  );
};
export default memo(HistoryModal);
