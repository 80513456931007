import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/doctors/partials/Header';
import ImageItem from '../../components/doctors/partials/ImageItem';
import TopLeftMenu from '../../components/doctors/partials/topLeftMenu';
import loadingImg from '../../img/loading.gif';
import pencilIcon from '../../img/pencil.png';
import { deleteRequest, getRequest } from '../../utils/axiosRequests';
import {
  agePrint,
  capitalizeFirstLetter,
  deleteConfirmation,
  getAvatarName,
  getColors,
  separateDateTime,
  userRole,
} from '../../utils/helpers';

function PatientList() {
  //const currentDate = moment().format('YYYY-MM-DD');
  const [patients, setPatients] = useState([]);
  const [total, setTotal] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const userInfo = useSelector((state) => state.authReducer.data);
  const activeDoctor = useSelector(
    (state) => state.doctorsOfAssistantReducer.activeDoctor,
  );
  const [q, setQ] = useState('');
  const [patientDetails, setPatientDetails] = useState({});
  const [showPatientProfile, setShowPatientProfile] = useState(false);
  const handlePatientProfleClose = () => setShowPatientProfile(false);
  const [serialSort, setSerialSort] = useState(false);
  const navigate = useNavigate();
  const [showMoreFilter, setShowMoreFilter] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const patientSettings = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.patientSettings,
  );
  const ageProperties = patientSettings?.items?.find(
    (item) => item.name === 'age',
  );
  let { unitProperties } = ageProperties || {};

  const getPatients = (query) => {
    const queryVal = query === 'clear' ? '' : query;
    setIsDataLoading(true);
    const url = `patients/all-patients-list?page=${currentPage}${
      queryVal ? '&q=' + queryVal : ''
    }${'&perPage=' + perPage}&doctorId=${
      userRole(userInfo.userType) === 'assistant'
        ? activeDoctor.id
        : userInfo.id
    }`;

    getRequest(url)
      .then((data) => {
        setIsDataLoading(false);
        if (currentPage === 1) {
          setPatients([...data.patients]);
          setTotal(data.total);
        } else {
          if (patients.length < total) {
            setPatients([...patients, ...data.patients]);
            setTotal(data.total);
          }
        }
      })
      .catch((e) => {
        setIsDataLoading(false);
        console.log(e.message);
      })
      .finally(() => {
        // setIsDataLoading(true);
        // setIsPatientLoading(true);
      });
  };

  const deleteAction = (patientId) => {
    deleteRequest(`appointments/remove-appointments-with-patient/${patientId}`)
      .then((data) => {
        setPatients(patients.filter((patient) => patient.id !== patientId));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFilter = (e) => {
    e.preventDefault();
    setPatients([]);
    setTotal(0);
    setCurrentPage(1);
    getPatients(q);
  };

  const handleClear = () => {
    setQ('');
    setCurrentPage(1);
    getPatients('clear');
  };

  useEffect(() => {
    getPatients(q);
  }, [currentPage]);

  let count = 1;
  const handleRowClick = (patient) => {
    navigate('/patient-history', {
      state: {
        patientData: { ...patient },
      },
    });
  };

  const goToPrescription = (patient) => {
    navigate('/prescription', {
      state: {
        patientData: { ...patient },
        dob: patient?.dob,
        history: 'new',
      },
    });
  };

  const replaceId = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(replaceId);
    } else if (typeof obj === 'object' && obj !== null) {
      const newObj = {};
      for (const key in obj) {
        if (key === '_id') {
          newObj['id'] = obj[key];
        } else {
          newObj[key] = replaceId(obj[key]);
        }
      }
      return newObj;
    }
    return obj;
  };

  const goToPrescriptionWithData = async (patient) => {
    await getRequest(`prescriptions/${patient?.latestPrescriptionId}`)
      .then((data) => {
        delete data._id;
        delete data.createdAt;
        navigate('/prescription', {
          state: {
            prescription: replaceId(data),
            dob: data?.patientId?.dob,
            note: data?.note?.note,
            history: 'editFromPatientList',
            prescriptionId: patient?.latestPrescriptionId,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getPatientIdForDoctor = (idList) => {
    let patientId = '';
    idList?.forEach((item) => {
      if (item.doctorId === userInfo.id) {
        patientId = item.patientId;
      }
    });
    return patientId;
  };

  return (
    <div>
      <Header />
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-md-12 sticky-header shadow header-sticky-second">
            <TopLeftMenu />
          </div>
        </div>
        <Row className="rx-body">
          <div className="col-xl-12 col-md-12 col-lg-12">
            <div className="card shadow mb-4">
              <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                <h6 className="m-0 font-weight-bold text-primary">Patients</h6>
              </div>
              <div className="card-body appointment-card-body patient-list">
                <div style={{ margin: '15px' }}>
                  <Form>
                    <Row>
                      <Col xs={6} sm={7} md={8} lg={9}>
                        <Form.Control
                          type="text"
                          className="form-control-sm"
                          placeholder="Name, Patient Id, Phone number, Chief complain, Diagnosis, Investigation, Medicine"
                          value={q}
                          onChange={(e) => setQ(e.target.value)}
                        />
                      </Col>
                      <Col xs={6} sm={5} md={4} lg={3}>
                        <Button
                          size="sm"
                          variant="outline-success"
                          type="submit"
                          onClick={handleFilter}
                        >
                          <i className="fa fa-filter"></i> Filter
                        </Button>
                        <Button
                          size="sm"
                          style={{ marginLeft: '10px' }}
                          variant="outline-danger"
                          type="button"
                          onClick={handleClear}
                        >
                          <i className="fa fa-redo" title="Show More"></i> Clear
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>No</th>
                        <th>Date</th>
                        <th width="20%">Name</th>
                        <th width="15%">Patient Id</th>
                        <th>Mobile Number</th>
                        <th></th>
                        <th className="text-right">
                          <span style={{ fontWeight: 'bold', fontSize: '15px',}}>{total > 0 && patients?.length} of {total}</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (count =
                          1 &&
                          patients &&
                          patients.map((patient, key) => (
                            <tr
                              key={patient.id}
                              onClick={() => handleRowClick(patient)}
                            >
                              <td>{count++}</td>
                              <td>
                                {patient?.createdAt
                                  ? moment(patient?.createdAt).format(
                                      'DD, MMM YYYY',
                                    )
                                  : 'N/A'}
                              </td>
                              <td className="patient-name-img">
                                {patient?.patientDetails?.image ? (
                                  <ImageItem patientId={patient.id} />
                                ) : (
                                  <div className="avatar-parent">
                                    <div
                                      key={patient.id}
                                      className="avatar-child"
                                      title={patient?.name}
                                      style={{
                                        background: [
                                          getColors[
                                            Math.floor(Math.random() * 10)
                                          ],
                                        ],
                                        color: '#ffffff',
                                        //border: `1px solid ${getColors[Math.floor(Math.random() * 10)]}`,
                                      }}
                                    >
                                      {getAvatarName(
                                        patient?.name,
                                      ).toUpperCase()}
                                    </div>
                                  </div>
                                )}
                                <div>
                                  <div className="name">
                                    {patient?.name || ''}
                                  </div>
                                  <div className="gender-age">
                                    <span>
                                      {capitalizeFirstLetter(patient?.gender)},
                                    </span>
                                    <span>
                                      {agePrint(
                                        separateDateTime(patient?.dob),
                                        unitProperties,
                                      )}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {getPatientIdForDoctor(
                                  patient.patientIdForDoctor,
                                )}
                              </td>
                              <td>{patient?.phoneNumber || ''}</td>
                              <td style={{ textAlign: 'right' }}>
                                {patient?.latestPrescriptionId && (
                                  <Button
                                    className="btn btn-md"
                                    variant="danger"
                                    style={{
                                      background: '#D8FFE9',
                                      border: 'none',
                                      color: '#00A64B',
                                      fontWeight: 'bold',
                                    }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      goToPrescriptionWithData(patient);
                                    }}
                                  >
                                    <i
                                      className="fa fa-edit pr-2"
                                      aria-hidden="true"
                                    ></i>
                                    <span>Edit</span>
                                  </Button>
                                )}
                                <Button
                                  className="btn btn-md ml-3"
                                  variant="danger"
                                  style={{
                                    background: '#FFDDE5',
                                    border: 'none',
                                    color: '#FF0000',
                                  }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    deleteConfirmation(
                                      deleteAction,
                                      patient.id,
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-trash-alt pr-2"
                                    aria-hidden="true"
                                  ></i>
                                  <span>Delete</span>
                                </Button>
                              </td>
                              <td className="text-end">
                                <Button
                                  type="button"
                                  className="btn btn-md consult-btn"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    goToPrescription(patient);
                                  }}
                                >
                                  <img src={pencilIcon} alt="icon" />
                                  <span>Consult Now</span>
                                  <i className="fa fa-angle-right pl-2"></i>
                                </Button>
                              </td>
                            </tr>
                          )))
                      }
                    </tbody>
                  </Table>
                  {isDataLoading && (
                    <img
                      src={loadingImg}
                      height={50}
                      width={50}
                      alt="Loading..."
                      style={{ marginLeft: '45%', marginTop: '5px' }}
                    />
                  )}
                  {total > 0 && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>
                        {patients && patients?.length < total && (
                          <Button
                            size="sm"
                            onClick={() => setCurrentPage(currentPage + 1)}
                          >
                            Load More
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                  {/* <PaginationComponent
                    total={total}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    alwaysShown={true}
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <Modal
            size="lg"
            show={showPatientProfile}
            onHide={handlePatientProfleClose}
          >
            <Modal.Header className="common-modal-header">
              <Col>
                <Modal.Title>Patient profile</Modal.Title>
              </Col>
              <Col md={4}></Col>
              <Col md={1} style={{ textAlign: 'end' }}>
                <i
                  className="fa fa-times cursor-pointer"
                  aria-hidden="true"
                  onClick={handlePatientProfleClose}
                ></i>
              </Col>
            </Modal.Header>
            <Modal.Body>
              <b>Name</b>: {patientDetails.name ? patientDetails.name : ''}
              <br />
              <b>PatientId</b>: {patientDetails?.patientId || ''}
              <br />
              <b>Age</b>: {patientDetails?.age ? patientDetails?.age : ''}
              <br />
              <b>Phone Number</b>:{' '}
              {patientDetails?.phoneNumber ? patientDetails?.phoneNumber : ''}
              <br />
              <b>Gender</b>:{' '}
              {patientDetails?.gender ? patientDetails?.gender : ''}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handlePatientProfleClose}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </Row>
      </div>
    </div>
  );
}

export default PatientList;
