import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  medTypes: [],
};

export const medicineTypeSlice = createSlice({
  name: 'medicineTypeSlice',
  initialState,
  reducers: {
    medicineTypes: (state, action) => {
      state.medTypes = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { medicineTypes } = medicineTypeSlice.actions;
export default medicineTypeSlice.reducer;
