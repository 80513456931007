import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import {
  medicineHistoryFullName,
  medicineType,
} from '../../../../../utils/helpers';
import SelectedMedicineScheduleHistory from '../../history/selectedMedicineScheduleHistory';

const DrugHistoryView = ({
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
}) => {

  const medicineTypes = useSelector(
    (state) => state.medicineTypeReducer.medTypes,
  );

  const scheduleViews = (item) => {
    if (medicineType(item?.type)) {
      const isScheduleExist = Object.values(
        item?.itemDetails[0]?.quantities,
      ).every((value) => value === 0);
      if (isScheduleExist) {
        return;
      }
    } else {
      const isQtyExist = item?.itemDetails[0]?.quantity;
      if (isQtyExist === '') {
        return;
      }
    }

    return item?.itemDetails?.map((data, index) => {
      return (
        <SelectedMedicineScheduleHistory
          data={data}
          item={item}
          key={index}
          typeName={item?.type}
          prescriptionItems={prescriptionItems}
        />
      );
    });
  };

  const unselectMedicine = (type, itemId) => {
    const result = selectedHistories?.drugHistories[type]?.filter(
      (selectedItem) => selectedItem.id !== itemId,
    );
    selectedHistories.drugHistories[type] = result;
    setSelectedHistories({ ...selectedHistories });
  };

  return (
    <>
      <div className="his-sub-title">D/H</div>
      <ul>
        {selectedHistories?.drugHistories?.D_H?.map((item, index) => (
          <li>
            <Row
              className={`selected-medicine-row ${index === 0 ? 'mt-0' : ''}`}
              key={index}
            >
              <Col md={10} className="medicine-schedule-parent drug-view">
                <div className="text-left">
                  <span>{medicineHistoryFullName(item, medicineTypes)}</span>
                </div>
                {scheduleViews(item)}
              </Col>
              <Col md={1} className="pr-1">
                <i
                  className="fa fa-times-circle float-right pr-1 pt-1"
                  onClick={() => unselectMedicine('D_H', item.id)}
                ></i>
              </Col>
            </Row>
          </li>
        ))}
      </ul>
    </>
  );
};

export default DrugHistoryView;
