import { memo } from 'react';
import { useSelector } from 'react-redux';
import {
  getRxLineClasses,
  itemSettingStyle,
  medicineFormat,
  printQTY,
} from '../../../utils/helpers';

const SelectedMedicineSchedule = ({
  item,
  data,
  typeName,
  element,
  rxWhite,
  prescriptionItems,
}) => {
  const medicineTypes = useSelector(
    (state) => state.medicineTypeReducer.medTypes,
  );

  return (
    <div
      className="ml-4 rx-left-space"
      style={itemSettingStyle(prescriptionItems?.items?.rx?.subItemStyle || {})}
    >
      <span className={getRxLineClasses(element, 'rx-schedule')}>
        {(medicineFormat(medicineTypes, item?.type, 'Tablet') ||
          medicineFormat(medicineTypes, item?.type, 'Inj. Tablet')) &&
          Object.values(data.quantities)?.some((val) => val !== 0) &&
          Object.values(data.quantities)?.map((val, index) => (
            <span key={index} className={rxWhite}>
              {printQTY(val)}
              {++index === Object.values(data.quantities).length
                ? ' ' + data.quantityUnit
                : ' + '}
            </span>
          ))}

        {medicineFormat(medicineTypes, item?.type, 'Inj. Tablet') &&
          Object.values(data.quantities)?.some((val) => val !== 0) &&
          data?.route !== '' && <span className="rx-white"> (Route: {data?.route})</span>}

        {(medicineFormat(medicineTypes, item?.type, 'Injection') ||
          medicineFormat(medicineTypes, item?.type, 'Syrup')) &&
          (data?.quantity || data?.schedule) && (
            <span className={rxWhite}>
              {data.quantity ? (
                <>
                  {printQTY(data.quantity)} {data.quantityUnit}
                </>
              ) : (
                ''
              )}{' '}
              {data.schedule ? (
                <>
                  {printQTY(data.schedule)} {data.scheduleUnit}
                </>
              ) : (
                ''
              )}
            </span>
          )}

        {medicineFormat(medicineTypes, item?.type, 'Injection') &&
          (data?.quantity || data?.schedule) &&
          data?.route !== '' && <span className="rx-white"> (Route: {data?.route})</span>}

        <span className={`pr-2 ${rxWhite}`}></span>
      </span>
      <span className={getRxLineClasses(element, 'rx-instruction')}>
        {element?.lineDraw && element?.lineType !== 'none' && (
          <span className={`pl-2 ${rxWhite}`}></span>
        )}
        {data?.instructions?.map((insItem, key) => (
          <span key={key} className={rxWhite}>
            {insItem} {data.instructions.length !== key + 1 && ', '}
          </span>
        ))}
        {element?.lineDraw && element?.lineType !== 'none' && (
          <span className={`pr-2 ${rxWhite}`}></span>
        )}
      </span>
      <span className="medicine-duration">
        <span className={`pl-2 ${rxWhite}`}></span>
        {data.durationUnit === 'চলবে'
          ? data.durationUnit
          : data.duration
          ? data.duration === ''
            ? ''
            : data.duration + ' ' + data.durationUnit
          : ''}
      </span>
      {element?.lineDraw && element.lineType === 'underscore' && (
        <div className="bottom-border"></div>
      )}
    </div>
  );
};

export default memo(SelectedMedicineSchedule);
