import React, { memo, useEffect, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useGetRequest } from '../../../hooks/useGetRequest';
import {
  deleteRequest,
  patchRequest,
  postRequest,
} from '../../../utils/axiosRequests';
import { medicineFormat } from '../../../utils/helpers';

const Suggestion = ({ medicine, handleDefaultData }) => {
  const [schedules, setSchedules] = useState([]);
  const [doseRecommendations, setDoseRecommendations] = useState([]);
  const userInfo = useSelector((state) => state.authReducer.data);
  const medicineTypes = useSelector(
    (state) => state.medicineTypeReducer.medTypes,
  );

  const { isLoading, refetch: getMedicineDefault } = useGetRequest(
    `getMedicineDefault-${medicine.id}`,
    `medicine-default?medicineId=${medicine.id}`,
    (data) => {
      setSchedules([...data.data]);
    },
    (e) => {
      console.log(e);
    },
  );

  const { isLoading: isDoseLoading, refetch: getDoseRecommendation } =
    useGetRequest(
      `getDoseRecom-${medicine.id}`,
      `medicine-default/dose-recommendation/${medicine.id}`,
      (data) => {
        setDoseRecommendations([...data.data]);
      },
      (e) => {
        console.log(e);
      },
    );

  const defaultAction = async (
    defaultId,
    medicineId,
    doctorId,
    val,
    scheduleData,
    type,
  ) => {
    await patchRequest(`medicine-default/${defaultId}`, {
      medicineId: medicineId,
      doctorId: doctorId,
      [type]: val,
      type,
    })
      .then((response) => {
        setSchedules([...response.data]);
        if (type === 'isSelected') {
          handleDefaultData(scheduleData);
        }
        toast.success('Updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const defaultByGeneric = async (
    defaultId,
    medicineId,
    scheduleData,
    isDefault,
  ) => {
    await patchRequest(`medicine-default/default-by-generic/${defaultId}`, {
      medicineId: medicineId,
      itemDetails: [scheduleData],
      isDefault: isDefault,
    })
      .then((response) => {
        setSchedules([...response.data]);
        handleDefaultData(scheduleData);
        toast.success('Updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteMakeDefault = async (delId) => {
    await deleteRequest(`medicine-default/${delId}`)
      .then((data) => {
        setSchedules(schedules?.filter((item) => item.id !== delId));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getMedicineDefault();
    getDoseRecommendation();
  }, [medicine.id]);

  const scheduleList = () => {
    return schedules?.map((parentData, key) => {
      return parentData?.itemDetails?.map((scheduleData, index) => {
        return (
          <div
            className={`schedule-instruction-row ${
              parentData?.isDefault ? 'default' : 'hover'
            } 
            ${parentData?.isSelected ? 'selected' : ''}
            `}
            key={index}
          >
            <div
              onClick={(e) => {
                e.stopPropagation();
                defaultAction(
                  parentData.id,
                  parentData.medicineId,
                  parentData.doctorId,
                  parentData?.isSelected ? false : true,
                  scheduleData,
                  'isSelected',
                );
              }}
              className={`schedule-instruction`}
            >
              <span className="schedule">
                {(medicineFormat(medicineTypes, medicine?.type, 'Tablet') ||
                  medicineFormat(
                    medicineTypes,
                    medicine?.type,
                    'Inj. Tablet',
                  )) &&
                  Object.values(scheduleData?.quantities)?.map(
                    (item, index) => (
                      <span key={index}>
                        {item}
                        {++index ===
                        Object.values(scheduleData?.quantities).length
                          ? ' ' + scheduleData?.quantityUnit
                          : ' + '}
                      </span>
                    ),
                  )}
                {(medicineFormat(medicineTypes, medicine?.type, 'Injection') ||
                  medicineFormat(medicineTypes, medicine?.type, 'Syrup')) && (
                  <span>
                    {scheduleData.quantity +
                      ' ' +
                      scheduleData.quantityUnit +
                      ' ' +
                      scheduleData.schedule +
                      ' ' +
                      scheduleData.scheduleUnit}
                  </span>
                )}
              </span>
              <span className="instruction">
                {scheduleData?.instructions?.map((insItem, key) => (
                  <span key={key}>
                    {insItem}{' '}
                    {scheduleData?.instructions?.length !== key + 1 && ', '}
                  </span>
                ))}
              </span>
              {((scheduleData?.duration && scheduleData?.durationUnit) ||
                scheduleData?.durationUnit === 'চলবে') && (
                <span style={{ width: '10%' }}>
                  {scheduleData.duration + ' ' + scheduleData.durationUnit}
                </span>
              )}
            </div>
            <div className="">
              <span>
                {parentData?.isDefault && parentData?.isGeneric ? (
                  <i
                    title="Delete Medicine Make Generic"
                    className="fas fa-gem gem-active pr-4"
                    aria-hidden="true"
                    onClick={(e) => {
                      e.stopPropagation();
                      defaultByGeneric(
                        parentData.id,
                        parentData.medicineId,
                        scheduleData,
                        false,
                      );
                    }}
                  ></i>
                ) : (
                  <i
                    title="Make Default By Generic"
                    className="fas fa-gem gem-inactive pr-4"
                    aria-hidden="true"
                    onClick={(e) => {
                      e.stopPropagation();
                      defaultByGeneric(
                        parentData.id,
                        parentData.medicineId,
                        scheduleData,
                        true,
                      );
                    }}
                  ></i>
                )}
              </span>
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  defaultAction(
                    parentData.id,
                    parentData.medicineId,
                    parentData.doctorId,
                    parentData.isDefault ? false : true,
                    scheduleData,
                    'isDefault',
                  );
                }}
              >
                {parentData?.isDefault ? (
                  <i
                    title="Delete Medicine Make Default"
                    className="fa fa-star star-active pr-4"
                    style={{ color: '#DAA520', cursor: 'pointer' }}
                  ></i>
                ) : (
                  <i
                    title="Make Default By Medicine"
                    className="fa fa-star star-inactive pr-4"
                    style={{ color: '#9aa8c9', cursor: 'pointer' }}
                  ></i>
                )}
              </span>
              <i
                onClick={(e) => {
                  e.stopPropagation();
                  deleteMakeDefault(parentData.id);
                }}
                title="Delete"
                className="fa fa-times"
                style={{ color: 'red' }}
              ></i>
            </div>
          </div>
        );
      });
    });
  };

  const doseRecommendToDefault = async (inputData) => {
    inputData.isDefault = true;
    console.log(inputData, 'inputData');
    await postRequest(`medicine-default`, inputData)
      .then((response) => {
        toast.success('Updated successfully', {
          position: toast.POSITION.TOP_RIGHT,
        });
        getMedicineDefault();
      })
      .catch((error) => {
        console.log(error);
        toast.error('Something went wrong', {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const doseRecommendationList = () => {
    return doseRecommendations
      ?.filter((data) => data.doctorId !== userInfo?.id)
      .map((parentDoseData, key) => {
        return parentDoseData?.itemDetails?.map((doseData, index) => {
          return (
            <div className={`schedule-instruction-row`} key={index}>
              <div
                onClick={() => handleDefaultData(doseData)}
                className={`schedule-instruction`}
              >
                <span className="schedule">
                  {(medicineFormat(medicineTypes, medicine?.type, 'Tablet') ||
                    medicineFormat(
                      medicineTypes,
                      medicine?.type,
                      'Inj. Tablet',
                    )) &&
                    Object.values(doseData?.quantities)?.map((item, index) => (
                      <span key={index}>
                        {item}
                        {++index === Object.values(doseData?.quantities).length
                          ? ' ' + doseData?.quantityUnit
                          : ' + '}
                      </span>
                    ))}
                  {(medicineFormat(
                    medicineTypes,
                    medicine?.type,
                    'Injection',
                  ) ||
                    medicineFormat(medicineTypes, medicine?.type, 'Syrup')) && (
                    <span>
                      {doseData.quantity +
                        ' ' +
                        doseData.quantityUnit +
                        ' ' +
                        doseData.schedule +
                        ' ' +
                        doseData.scheduleUnit}
                    </span>
                  )}
                </span>
                <span className="instruction">
                  {doseData?.instructions?.map((insItem, key) => (
                    <span key={key}>
                      {insItem}{' '}
                      {doseData?.instructions?.length !== key + 1 && ', '}
                    </span>
                  ))}
                </span>
                {((doseData?.duration && doseData?.durationUnit) ||
                  doseData?.durationUnit === 'চলবে') && (
                  <span style={{ width: '10%' }}>
                    {doseData.duration + ' ' + doseData.durationUnit}
                  </span>
                )}
              </div>
              <div className="">
                <span>
                  <i
                    title="Make Medicine"
                    className="fa fa-star star-inactive pr-4"
                    style={{ color: '#9aa8c9', cursor: 'pointer' }}
                    onClick={() => doseRecommendToDefault(parentDoseData)}
                  ></i>
                  {/* {parentDoseData?.isDefault ? (
                    <i
                      title="Delete Medicine Make Default"
                      className="fa fa-star star-active pr-4"
                      style={{ color: '#DAA520', cursor: 'pointer' }}
                    ></i>
                  ) : (
                    <i
                      title="Make Default By Medicine"
                      className="fa fa-star star-inactive pr-4"
                      style={{ color: '#9aa8c9', cursor: 'pointer' }}
                    ></i>
                  )} */}
                </span>
              </div>
            </div>
          );
        });
      });
  };

  // const doseRecommendationList = () => {
  //   return doseRecommendations
  //     ?.filter((data) => data.doctorId !== userInfo?.id)
  //     .map((parentDoseData, key) => {
  //       return parentDoseData?.itemDetails?.map((doseData, index) => {
  //         return (
  //           <div className={`schedule-instruction-row`} key={index}>
  //             <div
  //               onClick={() => handleDefaultData(doseData)}
  //               className={`schedule-instruction`}
  //             >
  //               <span className="schedule">
  //                 {isTabCabType &&
  //                   Object.values(doseData?.quantities)?.map((item, index) => (
  //                     <span key={index}>
  //                       {item}
  //                       {++index === Object.values(doseData?.quantities).length
  //                         ? ' ' + doseData?.quantityUnit
  //                         : ' + '}
  //                     </span>
  //                   ))}
  //                 {!isTabCabType && (
  //                   <span>
  //                     {doseData.quantity +
  //                       ' ' +
  //                       doseData.quantityUnit +
  //                       ' ' +
  //                       doseData.schedule +
  //                       ' ' +
  //                       doseData.scheduleUnit}
  //                   </span>
  //                 )}
  //               </span>
  //               <span className="instruction">
  //                 {doseData?.instructions?.map((insItem, key) => (
  //                   <span key={key}>
  //                     {insItem}{' '}
  //                     {doseData?.instructions?.length !== key + 1 && ', '}
  //                   </span>
  //                 ))}
  //               </span>
  //             </div>
  //             <div
  //               className={'make-default-btn'}
  //               onClick={() => doseRecommendToDefault(parentDoseData)}
  //             >
  //               Make Default
  //             </div>
  //           </div>
  //         );
  //       });
  //     });
  // };

  return (
    <Tab.Container defaultActiveKey="user-suggestion">
      <Nav variant="pills" className="mt-2 medicine-suggestion">
        <Nav.Item>
          <Nav.Link eventKey="user-suggestion">User Suggestion</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="dose-recommendation">
            Dose Recommendation
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="user-suggestion" className="user-suggestion">
          {schedules?.length > 0 && scheduleList()}
        </Tab.Pane>
        <Tab.Pane
          eventKey="dose-recommendation"
          className="dose-recommendation user-suggestion"
        >
          {doseRecommendations?.length > 0 && doseRecommendationList()}
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container>
  );
};

export default memo(Suggestion);
