import React, { Fragment, memo, useContext, useRef, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useClickAway } from 'react-use';
import { RxContext } from '../../../contexts/RxContext';
import {
  ROUTES,
  DURATION_DATA,
  DURATION_UNITS,
  getSchedules,
  getTabletUnitQty,
  medicineFormat,
  medicineFullName,
  medicineType,
  QTY_SCHEDULES,
  qtyUnitQuantities,
  allDurationData,
  tabletQtyScheduleALl,
} from '../../../utils/helpers';
import MedicineDefaultModalNew from '../modals/medicineDefaultNew';
import DurationButtons from '../partials/durationButtons';
import DurationUnitButtons from '../partials/durationUnitButtons';
import ScheduleButtons from '../partials/scheduleButtons';
import ScheduleInputButtons from '../partials/scheduleInputButtons';
import ScheduleUnitButtons from '../partials/scheduleUnitButtons';
import UnitButtons from '../partials/unitButtons';
import InstructionTypeHead from './instructionTypeHead';
import QuantityValBtns from './quantityValBtns';
import ArrowIcon from './rx/arrowIcon';

const SelectedMedicineItem = ({
  item,
  parentId,
  setParentId,
  selectedIndex,
  removeMedicine,
  medicineUpdated,
  handleSelectedMedicine,
  setMedicineDefaultData,
  isMedicineDefault,
  isAlterMedicine,
  setIsAlterMedicine,
  setIsMedicineDefault,
  medicineId,
  setMedicineId,
}) => {
  const { instructions, setInstructions } = useContext(RxContext);
  let count = selectedIndex;
  const [detailIndex, setDetailIndex] = useState(0);
  const [btnNo, setBtnNo] = useState('');
  const [showScheduleBtn, setShowScheduleBtn] = useState(false);
  const [scheduleIndex, setScheduleIndex] = useState('');
  const [showScheduleInputBtn, setShowScheduleInputBtn] = useState(false);
  const [showScheduleQuantityBtn, setShowScheduleQuantityBtn] = useState(false);
  const [showQtyUnitBtn, setShowQtyUnitBtn] = useState(false);
  const [showDurationBtn, setShowDurationBtn] = useState(false);
  const [showDurationUnitBtn, setShowDurationUnitBtn] = useState(false);
  const [showScheduleUnitBtn, setShowScheduleUnitBtn] = useState(false);
  const [showRouteBtn, setShowRouteBtn] = useState(false);
  const [showScInjQuantityUnit, setShowScInjQuantityUnit] = useState(false);
  const [type, setType] = useState('quantity');
  const [unitRowCount, setUnitRowCount] = useState(1);
  const buttonArray = [1, 2, 3, 4, 5, 6];
  const medicineTypes = useSelector(
    (state) => state.medicineTypeReducer.medTypes,
  );
  const prescriptionItems = useSelector(
    (state) => state?.prescriptionReducer?.prescriptionInfo?.prescriptionItems,
  );
  const isMedTypeName = prescriptionItems?.items?.rx?.isMedTypeName || false;

  const getScheduleInputFields = (data, indexNo) => {
    const { quantitiesField, quantities } = data || {};

    return quantitiesField?.map((field, key) => {
      const quantityValue = quantities?.[QTY_SCHEDULES[key]] || 0;
      const isAssigned = quantityValue !== 0;
      const scheduleKey = QTY_SCHEDULES[key];

      return (
        <Fragment key={key}>
          <Button
            size="sm"
            className={`medicine-schedule-input-btn ${
              isAssigned ? 'assigned-value' : ''
            }`}
            variant="outline-secondary"
            onClick={() => handleButtonInput(scheduleKey, indexNo)}
          >
            <span className="duration-unit">{quantityValue}</span>
            <span className="arrow-flex">
              <span
                className="icon icon-up"
                onClick={(e) => {
                  e.stopPropagation();
                  setQtyFieldNextOrPrev(scheduleKey, quantityValue, 'next');
                }}
              />
              <span
                className="icon icon-down"
                onClick={(e) => {
                  e.stopPropagation();
                  setQtyFieldNextOrPrev(scheduleKey, quantityValue, 'prev');
                }}
              />
            </span>
          </Button>
          {key !== quantitiesField.length - 1 && <div> + </div>}
        </Fragment>
      );
    });
  };

  const setQuantitiesField = (fieldPosition, value, type) => {
    if (type !== 'change') {
      btnFieldClear();
      setShowScheduleInputBtn(false);
    }
    let quantities = item.itemDetails[detailIndex]['quantities'];
    quantities[fieldPosition] = value;
    fieldUpdateWithValue('quantities', quantities);
  };

  const fieldUpdateWithValue = (fieldName, value) => {
    handleSelectedMedicine(fieldName, item.id, value, detailIndex);
  };

  const handleScheduleFieldNumber = (inputFields, itemDetailIndex) => {
    setShowScheduleBtn(false);
    handleSelectedMedicine(
      'quantitiesField',
      item.id,
      inputFields,
      itemDetailIndex,
    );
    let quantities = {};
    inputFields.forEach((field, key) => {
      quantities[QTY_SCHEDULES[key]] = 0;
    });
    handleSelectedMedicine('quantities', item.id, quantities, itemDetailIndex);
  };

  const removeInstruction = (insItem) => {
    const result = item?.itemDetails[detailIndex]?.instructions?.filter(
      (instruction) => instruction !== insItem,
    );
    fieldUpdateWithValue('instructionRemove', result);
  };

  const handleButtonInput = (fieldPosition, indexNo) => {
    if (item.itemDetails[indexNo]['quantities'][fieldPosition] === 0) {
      let quantities = item.itemDetails[indexNo]['quantities'];
      item.itemDetails[indexNo]['quantities'] = {
        ...quantities,
        [fieldPosition]: '১',
      };
      fieldUpdateWithValue('quantitiesData', item);
    }
    setScheduleIndex(fieldPosition);
    setShowScheduleInputBtn(showScheduleInputBtn ? false : true);
  };

  const selectedUnit = (changeType, fieldName, val) => {
    fieldUpdateWithValue(fieldName, val);
    if (changeType === 'select' || changeType === 'clickOutside') {
      btnFieldClear();
    }
  };

  const toggleUnitButton = (type, index) => {
    btnFieldClear();
    if (type === 'quantityUnit') {
      setShowQtyUnitBtn(showQtyUnitBtn ? false : true);
    } else if (type === 'scheduleUnit') {
      setShowScheduleUnitBtn(showScheduleUnitBtn ? false : true);
    } else if (type === 'duration') {
      setShowDurationBtn(showDurationBtn ? false : true);
    } else if (type === 'durationUnit') {
      setShowDurationUnitBtn(showDurationUnitBtn ? false : true);
    } else if (type === 'route') {
      setShowRouteBtn(showRouteBtn ? false : true);
    } else if (type === 'scInjQuantityUnit') {
      setShowScInjQuantityUnit(showScInjQuantityUnit ? false : true);
    }
  };

  const actionOnClick = (fieldName, value, changeType = '') => {
    fieldUpdateWithValue(fieldName, value);
    if (changeType !== 'change') {
      btnFieldClear();
    }
  };

  const btnFieldClear = () => {
    setShowQtyUnitBtn(false);
    setShowDurationBtn(false);
    setShowScheduleInputBtn(false);
    setShowScheduleUnitBtn(false);
    setShowScheduleQuantityBtn(false);
    setShowDurationUnitBtn(false);
    setShowRouteBtn(false);
    setShowScInjQuantityUnit(false);
    setUnitRowCount(1);
  };

  const updateOnClickActionBtn = (type) => {
    setShowScheduleQuantityBtn(showScheduleQuantityBtn ? false : true);
    setType(type);
  };

  const handleDefaultData = (scheduleData) => {
    fieldUpdateWithValue('itemDetails', [scheduleData]);
  };

  const duplicateSchedule = () => {
    let newSchedule = {
      quantitiesField: [1, 2, 3],
      schedule: '',
      scheduleUnit: 'বার',
      quantities: { first: 0, second: 0, third: 0 },
      quantity: '',
      quantityUnit: medicineType(item?.type) ? 'টা' : 'চামুচ',
      times: '১',
      timesUnit: 'বেলা',
      duration: '',
      durationUnit: 'দিন',
      instructions: [],
    };
    // item.itemDetails.push(newSchedule);
    // medicineUpdated(item, selectedIndex);
    item['itemDetails'] = [...item.itemDetails, newSchedule];
    medicineUpdated(item, selectedIndex);
  };

  const removeSchedule = (index) => {
    item.itemDetails = item.itemDetails.filter((item, key) => {
      return index !== key;
    });
    medicineUpdated(item, selectedIndex);
  };

  const handleScheduleBtnNumber = (index) => {
    setShowScheduleBtn(index);
  };

  const handleDetailIndex = (index) => {
    setDetailIndex(index);
  };

  const ref = useRef(null);
  useClickAway(ref, (e) => {
    const clickedArea = e.target;
    const isUnitRow = Array.from(clickedArea.classList).some((className) =>
      /^unit-row-\d+$/.test(className),
    );
    if (!isUnitRow) {
      setShowScheduleBtn(false);
      setShowScheduleInputBtn(false);
      setShowScheduleQuantityBtn(false);
      setShowScheduleUnitBtn(false);
      setShowQtyUnitBtn(false);
      setShowDurationBtn(false);
      setShowDurationUnitBtn(false);
      setShowRouteBtn(false);
      setShowScInjQuantityUnit(false);
      setUnitRowCount(1);
    }
  });

  const nextOrPrevVal = (value, fieldName, actionType) => {
    let array = [];

    switch (fieldName) {
      case 'quantityUnit':
        array = qtyUnitQuantities(medicineTypes, item.type);
        break;
      case 'durationUnit':
        array = DURATION_UNITS;
        break;
      case 'duration':
        array = allDurationData();
        break;
      case 'scheduleUnit':
        array = getSchedules(medicineTypes, item.type);
        break;
      case 'schedule':
      case 'quantity':
        array = tabletQtyScheduleALl();
        break;
      case 'route':
        array = ROUTES;
        break;
      default:
    }

    let index = array.indexOf(value);
    if (actionType === 'next') {
      if (
        (fieldName === 'quantity' || fieldName === 'schedule') &&
        ['১', '১/৪', '১/২', '৩/৪', '১ ১/২', '২ ১/২'].includes(value)
      ) {
        index = 7;
      } else if (
        (fieldName === 'quantity' || fieldName === 'schedule') &&
        ['২'].includes(value)
      ) {
        index = 9;
      } else {
        index = index === array.length - 1 ? index : index + 1;
      }
    } else {
      index = index === 0 ? index : index - 1;
    }
    fieldUpdateWithValue(fieldName, array[index]);
  };

  const setQtyFieldNextOrPrev = (fieldPosition, value, actionType) => {
    let quantities = item?.itemDetails[detailIndex]['quantities'];
    const array = tabletQtyScheduleALl();
    let index = array.indexOf(value === 0 ? String(value) : value);

    if (actionType === 'next') {
      if (['১', '১/৪', '১/২', '৩/৪', '১ ১/২', '২ ১/২'].includes(value)) {
        index = 7;
      } else if (['২'].includes(value)) {
        index = 9;
      } else {
        index = index === array.length - 1 ? index : index + 1;
      }
    } else {
      index = index === 0 ? index : index - 1;
    }
    quantities[fieldPosition] = array[index];
    fieldUpdateWithValue('quantities', quantities);
  };

  const selectedList = () => {
    return item?.itemDetails?.map((data, index) => {
      return (
        <div
          key={index}
          style={index > 0 ? { borderTop: '1px solid #fff' } : {}}
          onClick={() => handleDetailIndex(index)}
        >
          {medicineFormat(medicineTypes, item.type, 'tablet') && (
            <Row className="header-row">
              <Col lg={5} md={5} sm={5} xs={5}>
                <span onClick={() => handleScheduleBtnNumber(index)}>
                  Schedule <i className="fa fa-angle-down"></i>
                </span>
              </Col>
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Unit</span>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                Duration
              </Col>
            </Row>
          )}
          {medicineFormat(medicineTypes, item.type, 'injection') && (
            <Row className="header-row">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span>Quantity</span>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                <span>Schedule</span>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                <span>Route</span>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                Duration
              </Col>
            </Row>
          )}
          {medicineFormat(medicineTypes, item.type, 'syrup') && (
            <Row className="header-row">
              <Col lg={5} md={5} sm={5} xs={5}>
                <span>Quantity</span>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                <span>Schedule</span>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                Duration
              </Col>
            </Row>
          )}
          {medicineFormat(medicineTypes, item.type, 'inj. tablet') && (
            <Row className="header-row">
              <Col lg={4} md={4} sm={4} xs={4}>
                <span onClick={() => handleScheduleBtnNumber(index)}>
                  Schedule <i className="fa fa-angle-down"></i>
                </span>
              </Col>
              <Col lg={2} md={2} sm={2} xs={2}>
                <span>Route</span>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                <span>Unit</span>
              </Col>
              <Col lg={3} md={3} sm={3} xs={3}>
                Duration
              </Col>
            </Row>
          )}
          <Row className="mb-1">
            {/* For tab. cap. and similar  */}
            {medicineFormat(medicineTypes, item.type, 'tablet') && (
              <Fragment>
                <Col
                  lg={5}
                  md={5}
                  sm={5}
                  xs={5}
                  ref={ref}
                  className="text-center"
                >
                  {showScheduleBtn === index && (
                    <ScheduleButtons
                      itemDetailIndex={index}
                      buttonArray={buttonArray}
                      actionMethod={handleScheduleFieldNumber}
                    />
                  )}
                  <div className="common-input-fields">
                    {getScheduleInputFields(data, index)}
                  </div>
                </Col>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  className="common-input-fields"
                >
                  <Button
                    size="sm"
                    className={`medicine-schedule-input-btn ${
                      data?.quantityUnit ? 'assigned-value' : ''
                    }`}
                    variant="outline-secondary"
                    onClick={() => {
                      toggleUnitButton('quantityUnit');
                      setBtnNo('');
                    }}
                  >
                    <span className="duration-unit">{data?.quantityUnit}</span>
                    <ArrowIcon
                      fieldName={'quantityUnit'}
                      value={data.quantityUnit}
                      action={nextOrPrevVal}
                    />
                  </Button>
                </Col>
              </Fragment>
            )}
            {/* For SC Injection format Inj. Tablet */}
            {medicineFormat(medicineTypes, item.type, 'inj. tablet') && (
              <Fragment>
                <Col
                  lg={4}
                  md={4}
                  sm={4}
                  xs={4}
                  ref={ref}
                  className="text-center"
                >
                  {showScheduleBtn === index && (
                    <ScheduleButtons
                      itemDetailIndex={index}
                      buttonArray={buttonArray}
                      actionMethod={handleScheduleFieldNumber}
                    />
                  )}
                  <div className="common-input-fields">
                    {getScheduleInputFields(data, index)}
                  </div>
                </Col>
                <Col
                  lg={2}
                  md={2}
                  sm={2}
                  xs={2}
                  className="common-input-fields"
                >
                  <Button
                    size="sm"
                    className={`medicine-schedule-input-btn ${
                      data?.route !== '' ? 'assigned-value' : ''
                    }`}
                    variant="outline-secondary"
                    onClick={() => toggleUnitButton('route')}
                  >
                    <span className="medicine-text">{data.route}</span>
                    <ArrowIcon
                      fieldName={'route'}
                      value={data.route}
                      action={nextOrPrevVal}
                    />
                  </Button>
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  className="common-input-fields"
                >
                  <Button
                    size="sm"
                    className={`medicine-schedule-input-btn ${
                      data?.quantityUnit ? 'assigned-value' : ''
                    }`}
                    variant="outline-secondary"
                    onClick={() => {
                      toggleUnitButton('scInjQuantityUnit');
                      setBtnNo('');
                    }}
                  >
                    <span className="duration-unit">{data?.quantityUnit}</span>
                    <ArrowIcon
                      fieldName={'quantityUnit'}
                      value={data.quantityUnit}
                      action={nextOrPrevVal}
                    />
                  </Button>
                </Col>
              </Fragment>
            )}
            {(medicineFormat(medicineTypes, item.type, 'injection') ||
              medicineFormat(medicineTypes, item.type, 'syrup')) && (
              <Fragment>
                <Col
                  md={medicineFormat(medicineTypes, item.type, 'syrup') ? 5 : 4}
                  className="common-input-fields"
                >
                  <Button
                    size="sm"
                    className={`medicine-schedule-input-btn mr-1 ${
                      data?.quantity != 0 ? 'assigned-value' : ''
                    }`}
                    variant="outline-secondary"
                    onClick={() => updateOnClickActionBtn('quantity')}
                  >
                    <span className="duration-unit">{data.quantity}</span>
                    <ArrowIcon
                      fieldName={'quantity'}
                      value={data.quantity}
                      action={nextOrPrevVal}
                    />
                  </Button>
                  <Button
                    size="sm"
                    className={`medicine-schedule-input-btn mr-1 ${
                      data?.quantityUnit !== '' ? 'assigned-value' : ''
                    }`}
                    variant="outline-secondary"
                    onClick={() => {
                      toggleUnitButton('quantityUnit');
                      setBtnNo(1);
                    }}
                  >
                    <span className="medicine-text">{data.quantityUnit}</span>
                    <ArrowIcon
                      fieldName={'quantityUnit'}
                      value={data.quantityUnit}
                      action={nextOrPrevVal}
                    />
                  </Button>
                </Col>
                <Col
                  lg={3}
                  md={3}
                  sm={3}
                  xs={3}
                  className="common-input-fields"
                >
                  <Button
                    size="sm"
                    className={`medicine-schedule-input-btn ${
                      data?.schedule !== '' ? 'assigned-value' : ''
                    }`}
                    variant="outline-secondary"
                    onClick={() => updateOnClickActionBtn('schedule')}
                  >
                    <span>{data?.schedule}</span>
                    <ArrowIcon
                      fieldName={'schedule'}
                      value={data.schedule}
                      action={nextOrPrevVal}
                    />
                  </Button>
                  <Button
                    size="sm"
                    className={`medicine-schedule-input-btn ${
                      data?.scheduleUnit !== '' ? 'assigned-value' : ''
                    }`}
                    variant="outline-secondary"
                    onClick={() => toggleUnitButton('scheduleUnit')}
                  >
                    <span className="medicine-text">{data.scheduleUnit}</span>
                    <ArrowIcon
                      fieldName={'scheduleUnit'}
                      value={data.scheduleUnit}
                      action={nextOrPrevVal}
                    />
                  </Button>
                </Col>
                {medicineFormat(medicineTypes, item.type, 'injection') && (
                  <Col
                    lg={2}
                    md={2}
                    sm={2}
                    xs={2}
                    className="common-input-fields"
                  >
                    <Button
                      size="sm"
                      className={`medicine-schedule-input-btn ${
                        data?.route !== '' ? 'assigned-value' : ''
                      }`}
                      variant="outline-secondary"
                      onClick={() => toggleUnitButton('route')}
                    >
                      <span className="medicine-text">{data.route}</span>
                      <ArrowIcon
                        fieldName={'route'}
                        value={data.route}
                        action={nextOrPrevVal}
                      />
                    </Button>
                  </Col>
                )}
              </Fragment>
            )}

            <Col lg={3} md={3} sm={3} xs={3} className="common-input-fields">
              <Button
                size="sm"
                className={`medicine-schedule-input-btn ${
                  data?.duration !== '' ? 'assigned-value' : ''
                }`}
                variant="outline-secondary"
                onClick={() => toggleUnitButton('duration')}
                disabled={data?.durationUnit === 'চলবে' ? true : false}
              >
                <span>{data.duration}</span>
                <ArrowIcon
                  fieldName={'duration'}
                  value={data.duration}
                  action={nextOrPrevVal}
                />
              </Button>
              <Button
                size="sm"
                className={`medicine-schedule-input-btn ${
                  data?.durationUnit !== '' ? 'assigned-value' : ''
                }`}
                variant="outline-secondary"
                onClick={() => toggleUnitButton('durationUnit')}
              >
                <span className="duration-unit">{data.durationUnit}</span>
                <ArrowIcon
                  fieldName={'durationUnit'}
                  value={data.durationUnit}
                  action={nextOrPrevVal}
                />
              </Button>
            </Col>
            <Col className="text-center">
              {index > 0 && (
                <i
                  className="fa fa-times-circle cursor-pointer"
                  onClick={() => removeSchedule(index)}
                  aria-hidden="true"
                ></i>
              )}
            </Col>
          </Row>
          {showScheduleQuantityBtn && detailIndex === index && (
            <Fragment>
              <Row>
                <Col md={9} ref={ref}>
                  <QuantityValBtns
                    inputArr={getTabletUnitQty(unitRowCount)}
                    actionOnClick={actionOnClick}
                    type={type}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={7} className="text-center">
                  <i
                    className={`fa ${
                      unitRowCount === 4 ? 'fa-angle-up' : 'fa-angle-down'
                    } cursor-pointer p-2 unit-row-${unitRowCount}`}
                    aria-hidden="true"
                    onClick={() =>
                      setUnitRowCount((prevCount) =>
                        prevCount + 1 > 4 ? 1 : prevCount + 1,
                      )
                    }
                  ></i>
                </Col>
              </Row>
            </Fragment>
          )}
          {showScheduleUnitBtn && detailIndex === index && (
            <Row>
              <Col md={5} sm={5} xs={5}></Col>
              <Col ref={ref}>
                <ScheduleUnitButtons
                  unitArray={getSchedules(medicineTypes, item.type)}
                  selectedUnit={selectedUnit}
                />
              </Col>
            </Row>
          )}
          {showScheduleInputBtn && detailIndex === index && (
            <Fragment>
              <Row>
                <Col md={9} ref={ref}>
                  <ScheduleInputButtons
                    inputArr={getTabletUnitQty(unitRowCount)}
                    actionOnClick={setQuantitiesField}
                    scheduleIndex={scheduleIndex}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={7} className="text-center">
                  <i
                    className={`fa ${
                      unitRowCount === 4 ? 'fa-angle-up' : 'fa-angle-down'
                    } cursor-pointer p-2 unit-row-${unitRowCount}`}
                    aria-hidden="true"
                    onClick={() =>
                      setUnitRowCount((prevCount) =>
                        prevCount + 1 > 4 ? 1 : prevCount + 1,
                      )
                    }
                  ></i>
                </Col>
              </Row>
            </Fragment>
          )}
          {showQtyUnitBtn && detailIndex === index && (
            <Row>
              {/* {btnNo !== 1 && <Col md={6}>{btnNo}</Col>} */}
              <Col
                md={medicineFormat(medicineTypes, item.type, 'tablet') ? 6 : 1}
              ></Col>
              <Col ref={ref}>
                <UnitButtons
                  unitArray={qtyUnitQuantities(medicineTypes, item.type)}
                  selectedUnit={selectedUnit}
                />
              </Col>
            </Row>
          )}
          {showScInjQuantityUnit && detailIndex === index && (
            <Row>
              <Col
                md={
                  medicineFormat(medicineTypes, item.type, 'Inj. Tablet')
                    ? 6
                    : 2
                }
              ></Col>
              <Col ref={ref}>
                <UnitButtons
                  unitArray={qtyUnitQuantities(medicineTypes, item.type)}
                  selectedUnit={selectedUnit}
                />
              </Col>
            </Row>
          )}
          {showRouteBtn && detailIndex === index && (
            <Row>
              {medicineFormat(medicineTypes, item.type, 'Injection') && (
                <Col ref={ref} md={6} sm={6} xs={6}></Col>
              )}
              {medicineFormat(medicineTypes, item.type, 'Inj. Tablet') && (
                <Col ref={ref} md={3} sm={3} xs={3}></Col>
              )}
              <Col ref={ref} style={{ paddingLeft: '35px' }}>
                <UnitButtons
                  unitArray={ROUTES}
                  selectedUnit={selectedUnit}
                  fieldName="route"
                />
              </Col>
            </Row>
          )}
          {detailIndex === index && showDurationBtn && (
            <Row>
              <Col md={5} sm={4} xs={4}></Col>
              <Col ref={ref}>
                <DurationButtons
                  unitArray={DURATION_DATA}
                  selectedUnit={selectedUnit}
                />
              </Col>
            </Row>
          )}
          {showDurationUnitBtn && detailIndex === index && (
            <Row>
              <Col md={7} sm={7} xs={7}></Col>
              <Col ref={ref}>
                <DurationUnitButtons
                  inputArr={DURATION_UNITS}
                  actionOnClick={actionOnClick}
                  type={type}
                />
              </Col>
            </Row>
          )}
          <Row className="mt-2 mb-1">
            <Col lg={7} md={7} sm={7} xs={7}>
              {item?.itemDetails[index]?.instructions?.map((insItem, index) => (
                <Button
                  key={index}
                  variant="primary"
                  className="btn btn-sm mr-2 mb-1 customize-btn ins-btn"
                  onClick={() => removeInstruction(insItem)}
                >
                  {insItem}
                </Button>
              ))}
            </Col>
            <Col
              lg={5}
              md={5}
              sm={5}
              xs={5}
              className="medicine-remarks"
              style={{ position: 'static' }}
            >
              <InstructionTypeHead
                index={index}
                item={item}
                detailIndex={detailIndex}
                instructions={instructions}
                setInstructions={setInstructions}
                fieldUpdateWithValue={fieldUpdateWithValue}
              />
            </Col>
          </Row>
        </div>
      );
    });
  };

  return (
    <>
      <div className="selected-medicine-item">
        <Row className="ml-0 mr-0">
          {/* style={{ /whiteSpace: 'nowrap' }} */}
          <Col lg={8} md={8} sm={8} xs={8}>
            {++count}. {medicineFullName(item, 'original', medicineTypes, isMedTypeName)}
            {item?.alterMedicine &&
              medicineFullName(item.alterMedicine, 'alter', medicineTypes, isMedTypeName)}
            <i
              className="fa fa-bars ml-3"
              title="More information"
              onClick={() => {
                setIsMedicineDefault(true);
                setMedicineId(item.id);
              }}
            ></i>{' '}
            <i
              className="fa fa-copy"
              title="Multiple Medicine Schedule"
              onClick={() => duplicateSchedule()}
            ></i>
            {/* <i
              className={`fa fa-star ${
                isDefault || item?.isDefaultSchedule
                  ? 'star-active'
                  : 'star-inactive'
              }`}
              title="Make default"
              onClick={() => makeDefault()}
            ></i> */}
            {/* <i
              className={`fa fa-star ${
                isDefault ? 'star-active' : 'star-inactive'
              }`}
              title="Make default"
              onClick={() => makeDefault()}
            ></i> */}
            {item.parentId === '' && (
              <i
                className={`fa fa-plus-circle ${
                  parentId ? 'plus-active' : 'alter-inactive'
                }`}
                title="Parent medicine"
                onClick={() => setParentId(parentId ? '' : item.id)}
                aria-hidden="true"
              ></i>
            )}
            <i
              class={`fa fa-sync ml-3 ${
                isAlterMedicine ? 'alter-active' : 'alter-inactive'
              }`}
              onClick={() => setIsAlterMedicine(isAlterMedicine ? '' : item.id)}
              title="Alter medicine"
              aria-hidden="true"
            ></i>
          </Col>
          <Col className="text-right">
            <i
              className="fa fa-times-circle"
              onClick={() => removeMedicine(item)}
              aria-hidden="true"
            ></i>
          </Col>
        </Row>
        {selectedList()}
      </div>
      {isMedicineDefault && medicineId === item.id && (
        <MedicineDefaultModalNew
          item={item}
          removeSchedule={removeSchedule}
          duplicateSchedule={duplicateSchedule}
          handleDefaultData={handleDefaultData}
          isTabCabType={medicineType(item.type)}
          setIsMedicineDefault={setIsMedicineDefault}
          handleSelectedMedicine={handleSelectedMedicine}
        />
      )}
    </>
  );
};

export default memo(SelectedMedicineItem);
