import { memo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { medicineFormat } from '../../../../utils/helpers';

const FormatsAndType = ({
  medicine,
  removeSchedule,
  duplicateSchedule,
  handleSelectedMedicine,
}) => {
  const medicineTypes = useSelector(
    (state) => state.medicineTypeReducer.medTypes,
  );

  const planAction = (type) => {
    if (type === 'minus' && medicine?.itemDetails?.length > 1) {
      removeSchedule(medicine?.itemDetails?.length - 1);
    } else if (type === 'plus' && medicine?.itemDetails?.length < 5) {
      duplicateSchedule();
    }
  };

  const isTabType = medicineFormat(medicineTypes, medicine?.type, 'Tablet');
  const isSyrupType = medicineFormat(medicineTypes, medicine?.type, 'Syrup');
  const isInjType = medicineFormat(medicineTypes, medicine?.type, 'Injection');
  const isInjTabType = medicineFormat(medicineTypes, medicine?.type, 'Inj. Tablet');

  return (
    <>
      <div className="format-items">
        <h5>Formats</h5>
        <div
          className={`${
            isTabType ? 'is-type-selected' : ''
          }`}
          onClick={() =>
            handleSelectedMedicine('medicineType', medicine.id, 'Tablet')
          }
        >
          Tablet
        </div>
        <div
          className={`${isSyrupType ? 'is-type-selected' : ''}`}
          onClick={() =>
            handleSelectedMedicine('medicineType', medicine.id, 'Syrup')
          }
        >
          Syrup
        </div>
        <div
          className={`${isInjType ? 'is-type-selected' : ''}`}
          onClick={() =>
            handleSelectedMedicine('medicineType', medicine.id, 'Injection')
          }
        >
          Injection
        </div>
        <div
          className={`${isInjTabType ? 'is-type-selected' : ''}`}
          onClick={() =>
            handleSelectedMedicine('medicineType', medicine.id, 'SC Injection')
          }
        >
          SC Injection
        </div>
      </div>
      <hr />
      <h5>Type</h5>
      <Row className="type-item">
        <Col md={9}>Multiple Medicine Plan</Col>
        <Col className="type">
          <span onClick={() => planAction('minus')}>
            <i className="fa fa-minus"></i>
          </span>
          <span>{medicine?.itemDetails?.length}</span>
          <span onClick={() => planAction('plus')}>
            <i className="fa fa-plus"></i>
          </span>
        </Col>
      </Row>
    </>
  );
};

export default memo(FormatsAndType);
