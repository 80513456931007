import React, { Fragment, memo } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { chunkArray, englishToBanglaNumber } from '../../../utils/helpers';

const QuantityValBtns = ({ inputArr, type, actionOnClick }) => {

  const handleSchedule = (val, changeType) => {
    actionOnClick(type, englishToBanglaNumber(val), changeType);
  };

  const chunks = chunkArray(inputArr);

  return (
    <Fragment>
      {chunks.map((chunk, chunkIndex) => (
        <Row key={chunkIndex}>
          <Col>
            <div className="unit-btn">
              {chunk.map((item, index) => (
                <Button
                  key={index}
                  size="sm"
                  className="btn-sm-customize"
                  variant="outline-secondary"
                  onClick={() => handleSchedule(item, 'click')}
                >
                  {item}
                </Button>
              ))}
              {chunkIndex === chunks.length - 1 && (
                <Form.Control
                  type="text"
                  style={{ width: '60px' }}
                  size="sm"
                  placeholder="যে কোন"
                  onChange={(e) => handleSchedule(e.target.value, 'change')}
                />
              )}
            </div>
          </Col>
        </Row>
      ))}
    </Fragment>
  );
};

export default memo(QuantityValBtns);
