import React, { memo } from 'react';
import {
  itemSettingStyle,
  styledComponent,
} from '../../../../../utils/helpers';
import CommonDeleteBtn from '../../commonDeleteBtn';

const InvestigationView = ({
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
  isHistoryPage,
}) => {
  const deleteData = (fieldName, delId) => {
    const updatedInvestigations = selectedHistories?.investigations?.filter(
      (investigation) => investigation.name !== delId,
    );
    setSelectedHistories({
      ...selectedHistories,
      investigations: updatedInvestigations,
    });
  };

  const Title = styledComponent(
    prescriptionItems?.items?.['history']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['history']?.subItemStyle || {},
  );

  return (
    <>
      {selectedHistories?.investigations?.length > 0 && (
        <div className="text-left">
          <div className="his-sub-title">I/H</div>
          <ul>
            {selectedHistories?.investigations?.map((data, index) => (
              <li key={index}>
                <span className="text-left pr-5">
                  <Title>{data.name} </Title>
                  <Value>
                    {data?.result && (
                      <span className="pl-1">
                        -{' '}
                        <i>
                          {data.result} {data?.unit}
                        </i>
                      </span>
                    )}
                  </Value>
                </span>
                {!isHistoryPage && (
                  <CommonDeleteBtn
                    action={deleteData}
                    itemName={'investigations'}
                    delId={data.name}
                  />
                )}
                {data?.instruction && (
                  <div>
                    &nbsp;&nbsp; - <Value>{data.instruction}</Value>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default memo(InvestigationView);
