import React, { useState, useRef, Fragment } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

const SearchDrug = ({
  type,
  selectMedicines,
  handleOnInputChange,
  medicinesInSearch,
  setMedicinesInSearch,
}) => {
  const typeaheadRef = useRef(null);
  const filterBy = () => true;
  const [isLoading, setIsLoading] = useState(false);

  const handleDrug = (selectedOption) => {
    const item = selectedOption[0];
    selectMedicines(item, 'D_H');
    typeaheadRef.current.clear();
  };

  return (
    <div>
      <AsyncTypeahead
        labelKey={'brandName'}
        ref={typeaheadRef}
        filterBy={filterBy}
        id="async-example"
        isLoading={isLoading}
        options={medicinesInSearch}
        placeholder="Search here ..."
        onChange={handleDrug}
        minLength={1}
        value={''}
        onSearch={handleOnInputChange}
        size="sm"
        renderMenuItemChildren={(option, props) => (
          <Fragment>
            {option.id !== 'notFound' ? (
              <div>
                <span className="medicine-type">{option?.type}</span>
                <span
                  className="medicine-name"
                  title={option?.brandName + ' - ' + option?.strength}
                >
                  {option?.brandName + ' - ' + option?.strength}
                </span>
                <span className="medicine-company"> {option?.companyName}</span>
              </div>
            ) : (
              <span>
                <span className="float-right">
                  <i
                    className="fa fa-plus-circle text-right"
                    aria-hidden="true"
                  ></i>
                </span>
              </span>
            )}
          </Fragment>
        )}
      />
    </div>
  );
};

export default SearchDrug;
