import React, { memo } from 'react';
import { styledComponent } from '../../../../../utils/helpers';
import CommonDeleteBtn from '../../commonDeleteBtn';

const FamilyView = ({
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
  isHistoryPage,
  removeItemsData,
}) => {

  const Title = styledComponent(
    prescriptionItems?.items?.['history']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['history']?.subItemStyle || {},
  );

  return (
    <div key={`family`}>
      <div className="his-sub-title">F/H</div>
      <ul>
        <li className="text-left">
          <Title>
            Family:
          </Title>{' '}
          <Value>
            {selectedHistories.family}
          </Value>
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'family'}
              delId={'family'}
            />
          )}
        </li>
      </ul>
    </div>
  );
};

export default memo(FamilyView);
