import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';

const SelectedMedicalHistories = ({ selectedData, setSelectedHistories }) => {
  const [medical, setMedical] = useState([]);

  useEffect(() => {
    setMedical([...(selectedData?.medical ?? [])]);
  }, [selectedData]);

  const handleMedicalExtra = (index, fieldName, val) => {
    if (fieldName === 'present' || fieldName === 'absent') {
      selectedData.medical[index]['present'] = '';
      selectedData.medical[index]['absent'] = '';
    } else if (fieldName === 'unit' && val === 'long time') {
      selectedData.medical[index]['duration'] = '';
    }
    selectedData.medical[index][fieldName] = val;
    setSelectedHistories({ ...selectedData });
  };

  const clearHistoryInput = (index) => {
    selectedData.medical[index].present = '';
    selectedData.medical[index].absent = '';
    setSelectedHistories({ ...selectedData });
  };

  const removeMedicalHistory = (item) => {
    setSelectedHistories({
      ...selectedData,
      medical: selectedData?.medical.filter((data) => data.name !== item.name),
    });
  };

  const selectedHistory = () => {
    return medical?.map((item, index) => {
      return (
        <Row className="selected-item-row">
          <Col md={2} className="font-weight-bold pr-0 pl-1">
            {item.name}
          </Col>
          <Col md={3} className="pr-0">
            <div className="input-group">
              <Form.Check
                type={`radio`}
                id={`present-${index}`}
                className="mr-2"
                label={`Present`}
                checked={item?.present === 'yes'}
                onChange={(e) => handleMedicalExtra(index, 'present', 'yes')}
              />
              <Form.Check
                type={`radio`}
                id={`absent-${index}`}
                label={`Absent`}
                checked={item?.absent === 'no'}
                onChange={(e) => handleMedicalExtra(index, 'absent', 'no')}
              />
            </div>
          </Col>
          <Col md={1} className="pl-0">
            <Button
              size="sm"
              variant={item?.present || item?.absent ? 'primary' : 'light'}
              onClick={() => clearHistoryInput(index)}
            >
              Clear
            </Button>
          </Col>
          <Col md={3} className="pl-0">
            <div className="input-group">
              <Form.Control
                size="sm"
                type="number"
                min="0"
                placeholder="Duration"
                defaultValue={item?.duration}
                value={item?.duration}
                onChange={(e) =>
                  handleMedicalExtra(index, 'duration', e.target.value)
                }
              />
              <select
                className="form-control form-control-sm"
                value={item?.unit}
                defaultValue={selectedData.medical?.DM?.unit}
                onChange={(e) =>
                  handleMedicalExtra(index, 'unit', e.target.value)
                }
              >
                <option value={'day(s)'}>Day(s)</option>
                <option value={'week(s)'}>Week(s)</option>
                <option value={'month(s)'}>Month(s)</option>
                <option value={'year(s)'}>Year(s)</option>
                <option value={'long time'}>Long Time</option>
              </select>
            </div>
          </Col>
          <Col md={2} className="pl-0 pr-0">
            <Form.Control
              size="sm"
              className="w-60"
              type="text"
              placeholder="Add note"
              defaultValue={item?.note}
              onChange={(e) =>
                handleMedicalExtra(index, 'note', e.target.value)
              }
            />
          </Col>
          <Col className="text-center">
            <i
              className="fa fa-times-circle pt-2 cursor-pointer"
              aria-hidden="true"
              onClick={() => removeMedicalHistory(item)}
            ></i>
          </Col>
        </Row>
      );
    });
  };

  return <>{selectedHistory()}</>;
};

export default SelectedMedicalHistories;
