import React, { memo } from 'react';
import { styledComponent } from '../../../../../utils/helpers';
import CommonDeleteBtn from '../../commonDeleteBtn';

const PersonalView = ({
  selectedHistories,
  setSelectedHistories,
  prescriptionItems,
  isHistoryPage,
  removeItemsData,
}) => {

  const Title = styledComponent(
    prescriptionItems?.items?.['history']?.itemStyle || {},
  );
  const Value = styledComponent(
    prescriptionItems?.items?.['history']?.subItemStyle || {},
  );

  return (
    <>
      <div className="his-sub-title">P/H</div>
      <ul>
        <li className="text-left">
          {selectedHistories.personal.smoker && (
            <>
              <Title>
                Smoker:{' '}
              </Title>
              <Value>
                {' '}
                Yes{' '}
              </Value>
            </>
          )}
          {selectedHistories.personal.alcoholic && (
            <>
              <Title>
                Alcoholic:{' '}
              </Title>
              <Value>
                {' '}
                Yes
              </Value>
            </>
          )}
          {selectedHistories.personal.tobacco && (
            <>
              <Title>
                {' '}
                Tobacco:{' '}
              </Title>{' '}
              <Value>
                {' '}
                Yes
              </Value>
            </>
          )}
          {!isHistoryPage && (
            <CommonDeleteBtn
              action={removeItemsData}
              itemName={'personal'}
              delId={'personal'}
            />
          )}
        </li>
        {selectedHistories?.personal?.notes && (
          <li className="text-left">
            <Title>
              Note:
            </Title>{' '}
            <Value>
              {selectedHistories.personal?.notes}
            </Value>
          </li>
        )}
      </ul>
    </>
  );
};

export default memo(PersonalView);
